import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import Box from 'common/components/box/Box';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import styles from './ListChargesScreen.module.scss';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DATE_FORMAT_DEFAUT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE, SEGMENT_FEATURES } from 'Config';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import GaugeIcon from 'assets/svg/gauge.svg';
import NoImage from 'assets/img/asset-default-image.png';
import { useHistory, useParams } from 'react-router-dom';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import ChargesService from 'api/charges/ChargesService';
import { ChargeCounterListDto, ChargeDto } from 'api/charges/models/ChargeDto';
import { ChargesSearchCriteria } from 'api/charges/models/ChargesSearchCriteria';
import Loading from 'common/services/Loading';
import DateFormat from 'common/components/dateFormat/dateFormat';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import RenderSVG from '../../common/components/renderSVG/RenderSVG';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/popover/PopoverItem';
import { FaEllipsisH } from 'react-icons/fa';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Popover from 'common/components/popover/Popover';
import ChargeFiltersScreen, { Filters } from './components/ChargeFiltersScreen';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import AvatarWithText from 'common/components/avatar/AvatarWithText';
import { Paged } from 'api/common/types/Page';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import FilterList from 'common/components/filterList/FilterList';
import IconWithText from 'common/components/iconWithText/IconWithText';
import ReactTooltip from 'react-tooltip';
import { ChargesStatusColorEnum } from 'api/charges/enums/ChargesStatusColorEnum';
import CarIcon from 'assets/svg/car.svg';
import CoinsIcon from 'assets/svg/coins.svg';
import MediaIcon from 'assets/svg/media.svg';
import CounterBox from 'common/components/counterBox/CounterBox';
import ImportIcon from 'assets/svg/file-arrow-up-solid.svg';
import DeletedVehicleCircle from 'common/components/deletedVehicleCircle/DeletedVehicleCircle';
import { RequestStatusType } from 'screens/vehicle/components/carChargeTab/components/ChargeScreen';
import { GenericType } from 'api/genericTypes/enums/GenericType';
import VehiclesService from 'api/vehicles/VehiclesService';


export type Props = {
    isVehicle?: boolean;
    isDriver?: boolean;
    editable: boolean;
    onClickItem: (item: ChargeDto, isDetails: boolean) => void;
    createNewCharge?: () => void;
    criteriaProp?: ChargesSearchCriteria;
    onDeleteFromList?: () => void;
    tourReRender?: () => void;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ListChargesScreen = ({ onClickItem, createNewCharge, isVehicle, isDriver, criteriaProp, onDeleteFromList, editable, tourReRender, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();

    const imageCacheKey = useRef(new Date().getTime());

    const { driverId, vehicleId, month } = useParams<{ driverId: string, vehicleId: string, month: string }>();
    
    const [chargePage, setChargePage] = useState<Paged<ChargeDto> | null>(null);

    const [counters, setCounters] = useState<ChargeCounterListDto>();

    const [criteria, setCriteria] = useState<ChargesSearchCriteria>(!criteriaProp ? {
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        vehicleId: isVehicle ? vehicleId : undefined,
        responsibleId: isDriver ? driverId : undefined,
        orderColumn: 'c.date',
        orderBy: 'desc',
        startDate: month ? new Date(month) : undefined,
        endDate: month ? new Date(new Date(new Date(new Date(month).setMonth(new Date(month).getMonth() + 1)).setUTCHours(0)).setUTCSeconds(-1)) : undefined,
        vehiclesInactive: true
    } : criteriaProp);
    
    const [filters, setFilters] = useState<Filters>(month ? { startDate: criteria.startDate, endDate: criteria.endDate, vehiclesInactive: true } : { vehiclesInactive: true });
    const [filtersTotal, setFiltersTotal] = useState(month ? 1 : 0);
    const [vehicleSegmentFeatures,setVehicleSegmentFeatures] = useState<string[]>([])
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasImportChargesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_IMPORT_EXPENSES_WRITE']);
    const hasChargesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_CHARGES_WRITE']);
    
    const getData = async () => {
        try {
            Loading.show();
            let countersDB = null;
            countersDB = await ChargesService.getCountersList(criteria);
            const [charges,_vehicleSegmentFeatures] = await Promise.all([
                    ChargesService.getList(criteria),
                    criteria.vehicleId ? VehiclesService.getVehicleSegmentFeatures(criteria.vehicleId) : null
                ]);
            setChargePage(charges);
            setVehicleSegmentFeatures(_vehicleSegmentFeatures ?? [])

            if (countersDB) {
                setCounters(countersDB);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get charges list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        } finally {
            Loading.hide();
            tourReRender && tourReRender();
        }
    };


    const [itemToRemove, setItemToRemove] = useState<ChargeDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const showRemoveItemDialog = (item: ChargeDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const showField = (feature: string) => {
        return (vehicleSegmentFeatures.findIndex(x => x == feature) ?? -1) >= 0;
    }

    const renderTableActionCell = (
        row: ChargeDto,
    ) => {
        const urlDetails = (isVehicle || isDriver) ? `/${isDriver ? 'drivers' : 'vehicles'}/details/${isDriver ? driverId : vehicleId}/costs/details/${row.id}` : `charges/details/${row.id}`;
        const urlEdit = (isVehicle || isDriver) ? `/${isDriver ? 'drivers' : 'vehicles'}/details/${isDriver ? driverId : vehicleId}/costs/edit/${row.id}` : `charges/edit/${row.id}`;

        const refundApproved = row.refundHistory && row.refundHistory[0].status == RequestStatusType.APPROVED;

        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={urlDetails}>
                            {t('common.details')}
                        </DropdownItem>
                        { hasChargesWritePolicy && !(row.vehicleId && !row.vehicleActive) && !refundApproved && <DropdownItem url={urlEdit}>
                            {t('common.edit')}
                        </DropdownItem>}
                        { hasChargesWritePolicy && !(row.vehicleId && !row.vehicleActive) && !refundApproved && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const renderTypeAndKilometersCell = (row: ChargeDto) => {
        return (
            <div className={styles.cellContainer}>
                <div className={styles.rowFlex}>
                    {row.typeIconSvg && <RenderSVG item={row.typeIconSvg} className={styles.divSVG} />} 
                    {row.typeName != GenericType.FUEL && <span>{t('common.generic_types.' + row.typeName as any)}</span>}
                    {row.typeName == GenericType.FUEL && <span>{t('vehicle.fuels.' + row.fuelName as any)}</span>}
                </div>
                { showField(SEGMENT_FEATURES.KM) && <div className={styles.firstRow}>
                    <IconWithText
                        icon={GaugeIcon}
                        value={row.kilometers}
                        suffix={t('charges.list.kilometers_minified')}
                        isNumeric={true}
                    />
                </div>}
            </div>
        )
    };

    const tableColumns: ListingTableColumn<ChargeDto>[] = useMemo(() => {
        const cols: ListingTableColumn<ChargeDto>[] = [
            {
                name: t('charges.responsible_date'),
                renderCell: row => <div>
                    <AvatarWithText src={row.responsiblePhotoUrl}
                        size="x2"
                        hideAvatar={!row.responsibleName}><div className={styles.bold}>{row.responsibleName || '-'}</div></AvatarWithText>
                    <div className={styles.dateFrais}><DateFormat value={row.date} /></div>
                </div>,
                width: 'fill',
                onSearch: onTableFilter,
                hideOn: ['sm'],
                searchField: 'c.date'
            },
            {
                name: showField(SEGMENT_FEATURES.KM) ? t('charges.list.type_kilometers') : t('charges.list.type'),
                renderCell: renderTypeAndKilometersCell,
                width: 'fill',
                onSearch: onTableFilter,
                hideOn: ['sm', 'md'],
                searchField: 'type.name' 
            },
            {
                name: t('common.supplier'),
                renderCell: row => <>
                    <div data-tip={row.supplierUrl ? (row.supplierName || '-') : undefined}>
                        {row.supplierUrl && <img src={row.supplierUrl} style={{ height: '2.5rem' }}></img>}
                        {!row.supplierUrl && (row.supplierName || '-')}
                    </div>
                    <ReactTooltip /> 
                </>,
                hideOn: ['sm', 'md', 'lg', 'xl'],
                onSearch: onTableFilter,
                searchField: 'supplier.name'
            },
            {
                name: t('common.cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                renderCell: row => (
                    <div className={styles.costColumn}>
                        <div className={styles.moneyColumn}>
                            <MoneyFormat fixedDecimalScale={true} value={row.cost} />
                        </div>
                        {row.paymentDate ? (<>
                            <div
                                className={styles.secondRow}
                                data-tip={t('charges.payment_date')}
                            >
                                <DateFormat value={row.paymentDate} format={DATE_FORMAT_DEFAUT} />
                            </div>
                            <ReactTooltip />
                        </>) : ''}
                    </div>
                ),
                onSearch: onTableFilter,
                searchField: 'c.cost'
            },
            {
                name: t('charges.refund'),
                renderCell: row => <>
                    {row.requestRefund ?
                        <>
                            {row.refundHistory && row.refundHistory[0].status &&
                                <div className={styles.refundTreatedArea} style={{ color: (ChargesStatusColorEnum as any)[row.refundHistory[0].status] }}>
                                    <span>
                                        {t(('charges.list.request_status_type.' + row.refundHistory[0].status) as any)}
                                        {row.refundHistory[0].createdDate &&
                                            <>
                                                {' - '}
                                                <DateFormat value={row.refundHistory[0].createdDate} />
                                            </>
                                        }
                                    </span>
                                </div>}
                            {row.refundHistory && row.refundHistory[0].situation &&
                                <div className={styles.refundTreatedArea} style={{ color: (ChargesStatusColorEnum as any)[row.refundHistory[0].situation] }}>
                                    <span>
                                        {t(('charges.situations.' + row.refundHistory[0].situation) as any)}
                                        {row.refundHistory[0].dateRefundTreated &&
                                            <>
                                                {' - '}
                                                <DateFormat value={row.refundHistory[0].dateRefundTreated} />
                                            </>}
                                    </span>
                                </div>
                            }
                            {!row.refundHistory &&
                                <div className={styles.refundTreatedArea} style={{ color: ChargesStatusColorEnum.WAITING_APPROVAL }}>
                                    <span>{t('charges.waiting_approval')} </span>
                                </div>
                            }
                        </>
                        :
                        <div>
                            <span>{'-'}</span>
                        </div>

                    }
                </>,
                width: '20%',
                hideOn: ['sm', 'md', 'lg']
            }
        ];

        if (!isVehicle) {
            cols.unshift(
                {
                    renderCell: row =>
                        <div className={styles.imageDescCell}>
                            <div className={styles.contentImage}>
                                <div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: row?.vehiclePhotoUrl ? 'url(' + row?.vehiclePhotoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                            </div>
                        </div>,
                    width: 80,
                    hideOn: ['sm', 'md'],
                    onlyImageColumn: true
                },
                {
                    name: t('charges.list.vehicle'),
                    renderCell: row =>
                        <div className={styles.descCell}>
                            <div className={styles.columnPlate}>
                                {row.vehicleRegistrationNumber}
                                {!row.vehicleActive && <DeletedVehicleCircle id={row.id ?? ''} />}
                            </div>
                            <div className={styles.columnBrand}>{(row.vehicleBrandName ?? '') + (row.vehicleBrandName && row.vehicleModelName ?' | ' : '') + (row.vehicleModelName ?? '')}</div>
                        </div>,
                    onSearch: onTableFilter,
                    searchField: 'vehicle.registration_number',
                }
            );
        }

        //if (!isDriver) 
        //{
        cols.push({
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' },
            hideOn: ['sm', 'md', 'lg', 'xl']
        });
        //}
        return cols;
    }, []);


    const navigateTo = (type?: string, id?: string) => {
        if (id) {
            history.push(`/charges/${type}/${id}`);

        } else {
            history.push(`/charges/${type}`);
        }
    }

    const JumpToImport = () => {
        history.push(`/import-charges`);
    }

    const onRowClick = (event: any, id?: string) => {
        if (id) {
            event.preventDefault();
            event.stopPropagation();

            filters.page = criteria.page;
            saveFiltersOnCache(filters);


            if (event.ctrlKey) {
                window.open('charges/details/' + id, '_blank');
                return;
            }
            navigateTo('details', id);
        }
    }

    const onPageChange = (page: number) => {
        criteria.page = page;
        getData();
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            setShowRemoveModal(false);
            Loading.show();
            await ChargesService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
            Loading.hide();
            if (onDeleteFromList) {
                onDeleteFromList();
            }
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete charge`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    useEffect(() => {
        loadFilters();
    }, [criteria]);


    const saveFiltersOnCache = (f: Filters) => {
        setFilters(f);
        localStorage.setItem('CHARGESFILTER', JSON.stringify(f));
    }

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('CHARGESFILTER');
        if (cachedFilters != null && !driverId && !month) { onChangeFilters(JSON.parse(cachedFilters), true) }
        else { getData() }
    }

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const onChangeFilters = (f: Filters, isOnLoad?: boolean) => {
        updateTotalFilters(f);
        criteria.startDate = f.startDate;
        criteria.endDate = f.endDate;
        criteria.vehicleId = isVehicle ? vehicleId : f.vehicleId;
        criteria.typeId = f.typeId;
        criteria.fuelId = f.fuelId;
        criteria.supplierId = f.supplierId;
        criteria.responsibleId = f.responsibleId;
        criteria.minCost = f.cost && f.cost[0];
        criteria.maxCost = f.cost && f.cost[1];
        criteria.refundSituation = f.refundSituation;
        criteria.vehiclesInactive = f.vehiclesInactive;

        if (isOnLoad && f.page) {
            criteria.page = f.page
        } else {
            criteria.page = 1;
            f.page = 1;
        }
        
        saveFiltersOnCache(f);
        getData();
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.startDate || f.endDate) {
            total++;
        }
        if (f.vehicleId && f.vehicleId.length > 0) {
            total++;
        }
        if (f.typeId && f.typeId.length > 0) {
            total++;
        }
        if (f.fuelId && f.fuelId.length > 0) {
            total++;
        }
        if (f.supplierId && f.supplierId.length > 0) {
            total++;
        }
        if (f.responsibleId && f.responsibleId.length > 0 && !isDriver) {
            total++;
        }
        if (f.refundSituation != null) {
            total++;
        }
        if (f.cost && (f.cost[0] > 0 || f.cost[1] > 0)) {
            total++;
        }
        if (f.vehiclesInactive && !isVehicle) {
            total++;
        }

        setFiltersTotal(total);
    }

    const renderCounterBoxes = () => {
        return <div className={styles.rowCounters}>
            {counters && renderBox(MediaIcon, t('charges.list.average_cost_per_month', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }), counters.averageCostPerMonth ?? 0, true, t('charges.list.average_cost_per_month', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }))}
            {counters && renderBox(CarIcon, t('charges.list.average_cost_of_charge_per_car', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }), counters.averageCostChargePerCar ?? 0, true, t('charges.list.average_cost_of_charge_per_car', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }))}
            {counters && renderBox(CoinsIcon, t('charges.list.cumulative_total_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }), counters.cumulativeTotalCost ?? 0, true, t('charges.list.cumulative_total_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }))}
            <ReactTooltip effect="solid" id="main" />
        </div>
    }

    const renderBox = (icon: any, label: string, counter: number, isMoney?: boolean, tooltip?: string) => {
        return <CounterBox
            icon={icon}
            label={label}
            counter={counter}
            isMoney={isMoney}
            data-tip={tooltip}
            data-for="main"
        />
    }

    return (
        <ScreenTitle title={t('charges.title')} {...props}>
            <ScreenContainer>

                {(isVehicle || isDriver) &&
                    <div className={styles.tabHeader}>
                        <div>
                            <InputSearch onChangeValue={debounced} placeholder={t('common.search')}></InputSearch>
                        </div>
                        <div className={styles.rowFlex}>
                            {!criteriaProp && <Popover
                                contentContainerClassName={styles.filtersPopoverContainer}
                                containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                                positions={['left']}
                                onClickOutside={() => updateTotalFilters(filters)}
                                content={setIsPopoverOpen => <ChargeFiltersScreen
                                    isVehicle={isVehicle}
                                    isDriver={isDriver}
                                    filters={filters}
                                    onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                                    onChange={f => updateTotalFilters(f)}
                                />}>
                                {(isPopoverOpen, setIsPopoverOpen) => (
                                    <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                        {filtersTotal > 0 &&
                                            <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                        }
                                    </ScreenHeaderButton>
                                )}
                            </Popover>}
                            {createNewCharge && editable && hasChargesWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={createNewCharge} />}
                        </div>
                    </div>}

                {(!isVehicle && !isDriver) &&
                    <ScreenHeader title={t('charges.title')}>
                        <Popover
                            contentContainerClassName={styles.filtersPopoverContainer}
                            containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                            // positions={['bottom', 'left']}
                            // align={'end'}
                            onClickOutside={() => updateTotalFilters(filters)}
                            content={setIsPopoverOpen => <ChargeFiltersScreen
                                isVehicle={isVehicle}
                                isDriver={isDriver}
                                filters={filters}
                                onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                                onChange={f => updateTotalFilters(f)}
                            />}>
                            {(isPopoverOpen, setIsPopoverOpen) => (
                                <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                    {filtersTotal > 0 &&
                                        <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                    }
                                </ScreenHeaderButton>
                            )}
                        </Popover>
                        {hasImportChargesWritePolicy && <div data-tip={t('import_charges.tooltip')}><ScreenHeaderButton className={styles.emptyBorder} classNameIcon={styles.importIcon} icon={ImportIcon} onClick={() => JumpToImport()} /></div>}
                        {hasChargesWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateTo('new')} />}
                        <ReactTooltip />
                    </ScreenHeader>}

                {(isVehicle || isDriver) &&
                    <div>
                        <ListingTable
                            columns={tableColumns}
                            rows={chargePage?.items ?? []}
                            onRowClick={row => onClickItem(row, true)}
                            allowHover={true}
                            initialSearch={{ colField: 'c.date', isOrderAsc: true }} />
                        <PaginationWithInfo
                            itemName={t('charges.list.charges')}
                            currentPage={chargePage?.currentPage ?? 1}
                            pageItems={chargePage?.items.length ?? 0}
                            totalItems={chargePage?.totalItems ?? 0}
                            onChange={onPageChange} />
                    </div>}

                {(!isVehicle && !isDriver) && <>

                    <FilterList filters={[
                        {
                            value: filters.startDate && filters.endDate ? <>{t('common.range.from')} <DateFormat value={filters.startDate} /> {t('common.range.to').toLowerCase()} <DateFormat value={filters.endDate} /></>
                                : filters.startDate ? <>{t('common.range.from')} <DateFormat value={filters.startDate} /></>
                                    : filters.endDate ? <>{t('common.range.to')} <DateFormat value={filters.endDate} /></> : undefined,
                            label: t('charges.filter_date'),
                            onRemove: () => { filters.startDate = filters.endDate = undefined; onChangeFilters(filters) }
                        },
                        {
                            value: filters.vehicleName,
                            label: t('vehicle.vehicle'),
                            onRemove: () => { filters.vehicleId = filters.vehicleName = undefined; onChangeFilters(filters) }
                        },
                        {
                            value: filters.typeName,
                            label: t('common.type'),
                            onRemove: () => { filters.typeId = filters.typeName = undefined; onChangeFilters(filters) }
                        },
                        {
                            value: filters.fuelName,
                            label: t('vehicle.fuel'),
                            onRemove: () => { filters.fuelId = filters.fuelName = undefined; onChangeFilters(filters) }
                        },
                        {
                            value: filters.supplierName,
                            label: t('common.supplier'),
                            onRemove: () => { filters.supplierId = filters.supplierName = undefined; onChangeFilters(filters) }
                        },
                        {
                            value: filters.responsibleName,
                            label: t('common.responsible'),
                            onRemove: () => { filters.responsibleId = filters.responsibleName = undefined; onChangeFilters(filters) }
                        },
                        {
                            value: filters.refundSituation != null ? t('charges.refund') + ' ' + t(('charges.situations.' + filters.refundSituation) as any) : undefined,
                            onRemove: () => { filters.refundSituation = undefined; onChangeFilters(filters) }
                        },
                        {
                            value: !filters.cost || !filters.cost.length || !filters.cost.find(x => x !== undefined) ? undefined :
                                filters.cost.filter(i => i !== undefined).length === 2 ? <>{t('common.between')} <MoneyFormat value={filters.cost[0]} /> {t('common.and')} <MoneyFormat value={filters.cost[1]} /></>
                                    : filters.cost[0] !== undefined ? <>{t('common.above')} <MoneyFormat value={filters.cost[0]} /></>
                                        : <>{t('common.below')} <MoneyFormat value={filters.cost[1]} /></>,
                            label: t('common.cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                            onRemove: () => { filters.cost = undefined; onChangeFilters(filters) }
                        },
                        {
                            value: filters.vehiclesInactive ? t('common.yes') : '',
                            label: t('charges.vehicle_inactives'),
                            onRemove: () => { filters.vehiclesInactive = false; onChangeFilters(filters) }
                        },
                    ]} />

                    {counters && renderCounterBoxes()}

                    <Box className={styles.box}>
                        <ListingTable
                            columns={tableColumns}
                            rows={chargePage?.items ?? []}
                            onRowClick={(row, _, event) => onRowClick(event, row.id)}
                            onHref={row => `charges/details/${row.id}`}
                            allowHover={true}
                            initialSearch={{ colField: 'c.date', isOrderAsc: false }} />
                        <PaginationWithInfo
                            itemName={t('charges.list.charges')}
                            currentPage={chargePage?.currentPage ?? 1}
                            pageItems={chargePage?.items.length ?? 0}
                            totalItems={chargePage?.totalItems ?? 0}
                            onChange={onPageChange} />
                    </Box>
                </>}
                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={onRemove}
                    isVisible={showRemoveModal}
                    message={t('common.messages.remove_charge')} />
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default ListChargesScreen;
