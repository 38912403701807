import { UserProfile } from 'api/account/models/UserProfile';
import { CatalogBrandsSearchCriteria } from 'api/catalog/models/CatalogBrandsSearchCriteria';
import { CatalogModelsSearchCriteria } from 'api/catalog/models/CatalogModelsSearchCriteria';
import SuppliersService from 'api/suppliers/SuppliersService';
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';
import TrackingService from 'api/tracking/TrackingService';
import UsersService from 'api/users/UsersService';
import VehicleBrandsService from 'api/vehicleBrands/VehicleBrandsService';
import VehicleModelsService from 'api/vehicleModels/VehicleModelsService';
import { VehicleCondition } from 'api/vehicles/enums/VehicleCondition';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';
import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import VehiclesService from 'api/vehicles/VehiclesService';
import VehicleSegmentsService from 'api/vehicleSegments/VehicleSegmentsService';
import IconBack from 'assets/svg/Arrow-grey.svg';
import Box from 'common/components/box/Box';
import Button from 'common/components/button/Button';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import FormItem from 'common/components/formItem/FormItem';
import InputController from 'common/components/input/InputController';
import InputError from 'common/components/inputError/InputError';
import InputGroup from 'common/components/inputGroup/InputGroup';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import SelectController from 'common/components/select/SelectController';
import CustomFile from 'common/models/CustomFile';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Utils from 'common/services/Utils';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE, SEGMENT_FEATURES, removeAccents } from 'Config';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Types as CloseType } from 'screens/vehicle/VehicleScreen';
import { Reducers } from 'store/types';
import styles from './CarForm.module.scss';
import TechnicalInformationForm from './technicalInformationForm/TechnicalInformationForm';
import Label from 'common/components/label/Label';
import DriversService from 'api/drivers/DriversService';
import ToggleSwitch from 'common/components/toggleSwitch/ToggleSwitch';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import QrCode from 'common/components/qrCode/QRCode';
import Select from 'common/components/select/Select';
import { LocalBasicInfoViewModel } from 'api/locals/models/LocalViewModel';
import LocalsService from 'api/locals/LocalsService';
import VehicleFuelForm from './vehicleFuelForm/VehicleFuelForm';
import Input from 'common/components/input/Input';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import { CatalogSegmentsSearchCriteria } from 'api/catalog/models/CatalogSegmentsSearchCriteria';

type Props = {
    item?: VehicleViewModel;
    mode?: string;
    onCancel: (value?: CloseType, id?: any) => void;

} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const CarForm: React.FC<Props> = ({ item, mode, onCancel }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const form = useForm<VehicleViewModel>({ shouldUnregister: false, defaultValues: item ? item : { vehicleFuels: [] } });
    const { handleSubmit, setValue, control, register, errors, watch, getValues, reset } = form;
    const history = useHistory();

    const { returnUrl } = useParams<{ returnUrl: string }>();

    const [segmentFeatures, setSegmentFeatures] = useState<string[]>([]);
    const [localsOptions, setLocalsOptions] = useState<SelectValueLabel[]>([]);
    const [brandsOptions, setBrandsOptions] = useState<SelectValueLabel[]>([]);
    const [modelsOptions, setModelsOptions] = useState<SelectValueLabel[]>([]);
    const [typesOptions, setTypesOptions] = useState<SelectValueLabel[]>([]);
    const [fuelsOptions, setFuelsOptions] = useState<SelectValueLabel[]>([]);
    const [fuelSuppliersOptions, setFuelSuppliersOptions] = useState<SelectValueLabel[]>([]);
    const [colorsOptions, setColorsOptions] = useState<SelectValueLabel[]>([]);
    const [trackingDevicesOptions, setTrackingDevicesOptions] = useState<SelectValueLabel[]>([]);
    const [isLoadingDevicesList, setIsLoadingDevicesList] = useState<boolean>(false);

    const [selectedCondition, setSelectedCondition] = useState<string>('');
    const [conditionIsSelected, setConditionIsSelected] = useState<boolean>(true);
    const [medias, setMedias] = useState<CustomFile[]>([]);

    const [itemToRemove, setItemToRemove] = useState<VehicleViewModel>();
    const [itemToActivate, setItemToActivate] = useState<VehicleViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [dialogActivateItemIsOpen, setDialogActivateItemIsOpen] = React.useState(false);
    const [registrationAlreadyExists, setRegistrationAlreadyExists] = useState<string | undefined>(undefined);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehiclesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLES_WRITE']);
    const [driversOptions, setDriversOptions] = useState<SelectValueLabel[]>([]);

    const [technicalInformationsIdsToRemove, setTechnicalInformationsIdsToRemove] = useState<string[]>([]);

    const conditionsOptions: VehicleCondition[] = [
        VehicleCondition.NEW,
        VehicleCondition.USED
    ];

    const getData = async () => {
        Loading.show();

        try {
            setIsLoadingDevicesList(true);
            const trackingDevices = await TrackingService.getDevicesList();
            setTrackingDevicesOptions(Utils.sortAlphabetically(trackingDevices.map(x => ({ value: x.id, label: x.name }))));
            setIsLoadingDevicesList(false);

        } catch (err) {
            addToast(`${t('common.errors.tracking_auth')}. \n${t('common.messages.contact_admin')}`, { appearance: 'warning' });
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Provider Authentication Error - wrong credencials`, err);
            setIsLoadingDevicesList(false);
        }

        try {
            const [
                brands,
                models,
                types,
                fuels,
                fuelSuppliers,
                colors,
                drivers,
                locals
            ] = await Promise.all([
                VehicleBrandsService.catalog(),
                VehicleModelsService.catalog(),
                VehicleSegmentsService.catalog(),
                VehiclesService.getFuelList(),
                SuppliersService.getFuelSuppliers(),
                VehiclesService.getColorsList(),
                DriversService.catalog(),
                LocalsService.catalog()
            ]);

            setBrandsOptions(brands);
            setModelsOptions(models);
            setTypesOptions(types);
            setFuelsOptions(Utils.sortAlphabetically(fuels.map(x => ({ value: x.value, label: (t(('vehicle.fuels.' + x.label) as any)), fullLabel: x.label }))));
            setFuelSuppliersOptions(fuelSuppliers);
            setColorsOptions(Utils.sortAlphabetically(colors.map(x => ({ value: x.value, label: (t(('vehicle.colors.' + x.label) as any)) }))));

            setLocalsOptions(locals);

            if (item?.driverId && !drivers?.find(x => x.value == item.driverId)) {
                drivers.unshift({ value: item.driverId, label: item.driverName ?? '' });
                setDriversOptions(Utils.sortAlphabeticallyByKey(drivers, 'label'));
            }
            else {
                setDriversOptions(drivers);
            }

            if (item) {
                getBrandsBySegment();
                getModelByBrand(item.vehicleBrandId);
                setSelectedCondition(item.condition);
                if (selectedCondition && selectedCondition != '') {
                    setConditionIsSelected(true);
                }
            }

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get information to create vehicle`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const getSegmentFeatures = async () => {
        try {
            const features = await VehicleSegmentsService.getFeatures(form.getValues('vehicleSegmentId'));
            setSegmentFeatures(features);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get segment features`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getSegmentsByBrand = async (brandId?: string) => {
        try {
            const segments = await VehicleSegmentsService.catalog({ brandId } as CatalogSegmentsSearchCriteria);
            setTypesOptions(segments);

            if (!form.getValues('vehicleSegmentId') && segments.length == 1) {
                setValue('vehicleSegmentId', segments.find(x => x.value)?.value);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get segments by brand`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getBrandsBySegment = async () => {
        getSegmentFeatures();
        try {
            const brands = await VehicleBrandsService.catalog({ segmentId: form.getValues('vehicleSegmentId') } as CatalogBrandsSearchCriteria);
            setBrandsOptions(brands);

            if (form.getValues('vehicleSegmentId') && !brands?.length) {
                setModelsOptions([]);
                setValue('vehicleBrandId', null);
                setValue('vehicleModelId', null);
                return;
            }

            if (!form.getValues('vehicleBrandId') && brands.length == 1) {
                const brand = brands.find(x => x.value)?.value
                setValue('vehicleBrandId', brand);
                getModelByBrand(brand);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get brands of segment`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getBrandByModel = async () => {
        try {
            const brand = await VehicleBrandsService.catalog({ modelId: form.getValues('vehicleModelId') } as CatalogBrandsSearchCriteria);
            setBrandsOptions(brand);

            let brandId = form.getValues('vehicleBrandId');
            if (!brandId && brand.length == 1) {
                brandId = brand.find(x => x.value)!.value
                setValue('vehicleBrandId', brandId);
                getModelByBrand();
            }
            getSegmentsByBrand(brandId);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get brands of model`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getModelByBrand = async (brandId?: string) => {
        if (form.getValues('vehicleSegmentId') && !brandsOptions?.length && !brandId) {
            setModelsOptions([]);
            return;
        }

        brandId = brandId || form.getValues('vehicleBrandId');

        try {
            const models = await VehicleModelsService.catalog({ brandId } as CatalogModelsSearchCriteria);
            setModelsOptions(models);

            if (!form.getValues('vehicleModelId') && models.length == 1) {
                setValue('vehicleModelId', models.find(x => x.value)?.value);
                if (!brandId) {
                    getBrandByModel();
                }
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get models of brand`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const onSubmit = async (form: VehicleViewModel) => {

        const mediasToSubmit: CustomFile[] = [];

        try {
            if (!selectedCondition) {
                setConditionIsSelected(false);
            }

            if ((!form.vehicleSegmentId || !typesOptions.find(x => x.value === form.vehicleSegmentId))
                || !selectedCondition
                // || (form.vehicleFuels?.length ?? 0) == 0
                || (form.vehicleFuels?.filter(x => !x.fuelId)?.length ?? 0) > 0
                || !form.registrationNumber
                // || !form.registrationNumberDate
            ) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            let fuelsRepeated = false;
            if ((form.vehicleFuels?.length ?? 0) > 1) {
                form.vehicleFuels?.forEach((x, i) => {
                    if (!fuelsRepeated) {
                        fuelsRepeated = (form.vehicleFuels?.filter(z => z.fuelId == x.fuelId)?.length ?? 0) > 1;
                    }
                })

                if (fuelsRepeated) {
                    addToast(t('vehicle.fuels_repeated'), { appearance: 'warning' });
                    return;
                }
            }

            Loading.show();

            const model: VehicleDto = {
                id: form.id,
                registrationNumber: form.registrationNumber,
                registrationNumberDate: form.registrationNumberDate,
                vehicleBrandId: form.vehicleBrandId,
                vehicleModelId: form.vehicleModelId,
                vehicleSegmentId: form.vehicleSegmentId,
                condition: selectedCondition,
                colorId: form.colorId,
                removeAttachment: form.removeAttachment,
                currentKilometers: form.currentKilometers,
                trackingDeviceId: form.trackingDeviceId,
                totalCostCharges: form.totalCostCharges,
                totalCostMaintenances: form.totalCostMaintenances,
                totalCostContracts: form.totalCostContracts,
                fuelCard: form.fuelCard,
                fuelCardSupplierId: form.fuelCardSupplierId,
                averageTargetMonth: form.averageTargetMonth,
                fiscalPower: form.fiscalPower,
                dateCirculation: form.dateCirculation,
                reservationAvailable: form.reservationAvailable ?? false,
                defaultDriverId: form.defaultDriverId,
                technicalInformations: form.technicalInformations,
                vehicleLocals: form.vehicleLocals,
                vehicleFuels: form.vehicleFuels,
                active: true
            };

            if (form.technicalInformations) {
                form.technicalInformations.forEach(ti => {
                    ti.attachments.forEach(a => {
                        mediasToSubmit.push(a);
                    })
                })
            }
            if (form.photo) {
                form.photo.forEach(p => {
                    mediasToSubmit.push(p);
                })
            }

            const tempModel = { ...model, technicalInformationsIdsToRemove };

            if (!item) {
                const createId = await VehiclesService.create(tempModel, mediasToSubmit);
                addToast(t('common.messages.record_save_success'), { appearance: 'success' });
                navigateTo('details', createId);
            } else {
                model.id = item.id;
                await VehiclesService.update(tempModel, mediasToSubmit);
                addToast(t('common.messages.record_save_success'), { appearance: 'success' });
                navigateTo();
            }

            Loading.hide();
        } catch (error) {
            Loading.hide();

            if (error?.response?.status === 409) {
                addToast(showField(SEGMENT_FEATURES.KM) ? t('common.messages.registration_number_already_exists')  : t('common.messages.indentification_number_already_exists') , { appearance: 'warning' });
                setRegistrationAlreadyExists(form.registrationNumber);
                return;
            }

            if (error?.response?.status === 402) {
                addToast(t('common.errors.pack_limit_exceeded'), { appearance: 'warning' });
                return;
            }

            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
        }
    };

    const navigateTo = (type?: string, id?: string) => {
        if (mode == 'edit') {
            if (Boolean(item)) {
                //form.reset(item);
                getData();
            }
            onCancel(mode, item?.id);
        }
        else {
            if (type) {
                history.push(`/vehicles/${type}/${id}`);
            } else {
                if (returnUrl) {
                    history.push(`/${returnUrl}`);
                } else {
                    history.push(`/vehicles`);
                }
            }
        }
    }

    const renderConditionItem = (name: string, onClick: () => void, isSelected: boolean, key: number) => (
        <div
            onClick={(onClick)}
            key={`condition-${key}`}
            style={{ width: '50%' }}
        >
            <div className={styles.labelContainer}>
                <div className={` ${(isSelected ? styles.selected : styles.notSelected)} ${styles.labelContent}`}>
                    <span>{name}</span>
                </div>
            </div>
        </div>
    );

    const onSelectCondition = (type: any) => {
        if (mode !== 'details' && type) {
            setSelectedCondition(type || null);
        }
        if (item) {
            item.condition == type;

            if (type == VehicleCondition.NEW) {
                item.condition = VehicleCondition.USED;
            }
        }
        onSubmit;
    }

    const showRemoveItemDialog = (_item: VehicleViewModel) => {
        setItemToRemove(_item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await VehiclesService.remove(itemToRemove);
                addToast(t('common.messages.record_deactivate_success'), { appearance: 'success' });
                history.push(`/vehicles`);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_deactivate_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const showActivateItemDialog = (_item: VehicleViewModel) => {
        setItemToActivate(_item);
        setDialogActivateItemIsOpen(true);
    };


    const activateItem = async () => {
        setDialogActivateItemIsOpen(false);
        if (!!itemToActivate) {
            try {
                Loading.show();
                await VehiclesService.activate(itemToActivate);
                addToast(t('common.messages.record_activate_success'), { appearance: 'success' });
                history.push(`/vehicles`);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_activate_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onAddMedias = (_medias: CustomFile[]) => {
        setMedias(_medias);
        setValue('photo', _medias);
    }

    const onRemoveMedia = (mediaId: string) => {
        // mediaId is not enough because after submit and getData, mediaId that come from db is different than submited mediaId
        const filteredMedia = [...medias.filter(x => x.id != mediaId && x.containerName != 'vehiclePhoto')];
        setValue('photo', filteredMedia);
        setValue('removeAttachment', true);
        setMedias(filteredMedia);
    }

    const onRemoveTechnicalInformation = (id: string) => {
        if (id) {
            setTechnicalInformationsIdsToRemove([...technicalInformationsIdsToRemove, id]);
        }
    }

    const showField = (feature: string) => {
        return (segmentFeatures?.findIndex(x => x == feature) ?? -1) >= 0;
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        form.reset(item);
    }, [item]);

    useEffect(() => {
        form.setValue('defaultDriverId', undefined);
    }, [form.watch('reservationAvailable')]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={styles.box} style={{ boxShadow: !(mode != 'edit' && mode != 'details') ? 'none' : '' }}>
                {!item && mode != 'edit' && mode != 'details' && <div className={styles.header}>
                    <img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('vehicle.new')}
                </div>}

                {item && (mode == 'edit') && <div className={styles.header}>
                    <img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('vehicle.car_document')}
                </div>}

                <div className={styles.content}>
                    <Row >
                        <Row className={styles.fullWidth}>
                            <div className={styles.section}>
                                <div className={styles.sectionTitle}>
                                    {t('vehicle.vehicle_info')}
                                </div>
                            </div>
                        </Row>

                        <Row className={styles.fullWidth}>

                            <Row className={styles.fullWidth}>
                                <Col xs={12} sm={12} lg={6} xl={3} >
                                    {/* foto */}
                                    <FormItem id="four-step">
                                        <MediaSelector
                                            isMulti={false}
                                            isDetails={mode === 'details'}
                                            onAddMedias={onAddMedias}
                                            onRemoveMedia={onRemoveMedia}
                                            initialMedias={item?.photo ?? []}
                                            label={t('vehicle.add_photo')}
                                            container={'vehiclePhoto'}
                                            vehicleIcon={true}
                                        />
                                    </FormItem>
                                </Col>

                                <Col xs={12} sm={12} lg={6} xl={9} >
                                    <Row>
                                        {/* tipo */}
                                        <Col xs={12} sm={12} md={6} xl={4}>
                                            <FormItem>
                                                <Label className={styles.label}>{t('vehicle.type')} *</Label>
                                                <SelectController
                                                    form={form}
                                                    name="vehicleSegmentId"
                                                    placeholder={t('vehicle.type')}
                                                    isDisabled={mode === 'details'}
                                                    options={typesOptions}
                                                    rules={{ required: true }}
                                                    isClearable={true}
                                                    filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                                    onChangeSelect={(data: SelectValueLabel) => {
                                                        setValue('vehicleSegmentId', data ? data.value : null);
                                                        getBrandsBySegment();
                                                    }}
                                                />
                                                <InputError error={errors.vehicleSegmentId} />
                                            </FormItem>
                                        </Col>

                                        {/* marca */}
                                        <Col xs={12} sm={12} md={6} xl={4}>
                                            <FormItem>
                                                <Label className={styles.label}>{t('vehicle.brand')}</Label>
                                                <SelectController
                                                    form={form}
                                                    name="vehicleBrandId"
                                                    placeholder={t('vehicle.brand')}
                                                    isDisabled={mode === 'details'}
                                                    options={brandsOptions}
                                                    rules={{ required: false }}
                                                    isClearable={true}
                                                    filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                                    onChangeSelect={(data: SelectValueLabel) => {
                                                        setValue('vehicleBrandId', data ? data.value : null);
                                                        getModelByBrand();
                                                    }}
                                                />
                                                <InputError error={errors.vehicleBrandId} />

                                            </FormItem>
                                        </Col>

                                        {/* modelo */}
                                        <Col xs={12} sm={12} md={6} xl={4}>
                                            <FormItem>
                                                <Label className={styles.label}>{t('vehicle.model')}</Label>
                                                <SelectController
                                                    form={form}
                                                    name="vehicleModelId"
                                                    placeholder={t('vehicle.model')}
                                                    isDisabled={mode === 'details'}
                                                    options={modelsOptions}
                                                    rules={{ required: false }}
                                                    isClearable={true}
                                                    filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                                    onChangeSelect={(data: SelectValueLabel) => {
                                                        setValue('vehicleModelId', data ? data.value : null);
                                                        getBrandByModel();
                                                    }}
                                                />
                                                <InputError error={errors.vehicleModelId} />
                                            </FormItem>
                                        </Col>

                                        {/* matricula */}
                                        <Col xs={12} sm={12} md={6} xl={4}>
                                            <FormItem>
                                                <Label className={styles.label}>{showField(SEGMENT_FEATURES.KM) ? t('vehicle.registration') : t('vehicle.identification')} *</Label>
                                                <InputController
                                                    name="registrationNumber"
                                                    form={form as any}
                                                    autoComplete='off'
                                                    placeholder={showField(SEGMENT_FEATURES.KM) ? t('vehicle.registration') : t('vehicle.identification')}
                                                    rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }}
                                                    className={registrationAlreadyExists ? styles.warningInput : ''}
                                                    disabled={mode === 'details'}
                                                />
                                                <InputError error={errors.registrationNumber} />
                                                {!!registrationAlreadyExists && registrationAlreadyExists == form.getValues('registrationNumber') && <div className={styles.warningLabel}>{showField(SEGMENT_FEATURES.KM) ? t('common.messages.registration_number_already_exists')  : t('common.messages.indentification_number_already_exists') }</div>}
                                            </FormItem>
                                        </Col>

                                        {/* data matricula */}
                                        {showField(SEGMENT_FEATURES.KM) && <Col xs={12} sm={12} md={6} xl={4}>
                                            <FormItem>
                                                <Label className={styles.label}>{t('vehicle.registration_date')}</Label>
                                                <DateTimePickerController
                                                    form={form}
                                                    placeholderText={t('vehicle.registration_date')}
                                                    onChange={(date: Date) => {
                                                        setValue('registrationNumberDate', date);
                                                    }}
                                                    selected={watch('registrationNumberDate')}
                                                    dateFormat="MM/yyyy"
                                                    customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                                    name="registrationNumberDate"
                                                    autoComplete='off'
                                                    showMonthYearPicker
                                                    disabled={mode === 'details'}
                                                />
                                                <InputError error={errors.registrationNumberDate} />
                                            </FormItem>
                                        </Col>}


                                        {/* data circulacao */}
                                        {showField(SEGMENT_FEATURES.KM) && <Col xs={12} sm={12} md={6} xl={4}>
                                            <FormItem>
                                                <Label className={styles.label}>{t('vehicle.date_circulation')}</Label>
                                                <DateTimePickerController
                                                    form={form}
                                                    placeholderText={t('vehicle.date_circulation')}
                                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                                    onChange={() => { }}
                                                    dateFormat="DD/MM/yyyy"
                                                    customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                                    name="dateCirculation"
                                                    autoComplete='off'
                                                    rules={{ required: false }}
                                                    disabled={mode === 'details'}
                                                />
                                            </FormItem>
                                        </Col>}


                                    </Row>
                                </Col>
                            </Row>

                            <Row className={styles.fullWidth}>
                                <Col xs={12} sm={12} lg={6} xl={3} >
                                    {/* QRCode */}
                                    <FormItem>
                                        <QrCode value={form.watch('registrationNumber')} label={showField(SEGMENT_FEATURES.KM) ? t('vehicle.registration_qrCode') : t('vehicle.identification_qrCode') } 
                                        emptyMessage={showField(SEGMENT_FEATURES.KM) ? t('vehicle.add_registration') : t('vehicle.add_identification')} />
                                    </FormItem>
                                </Col>

                                <Col xs={12} sm={12} lg={6} xl={9}>
                                    <Row>

                                        {/* condicao */}
                                        <Col xs={12} sm={12} md={6} xl={4}>
                                            <FormItem>
                                                <Label className={styles.label}>{t('vehicle.condition')} *</Label>
                                                <div className={styles.condition} style={{ cursor: mode !== 'details' ? 'pointer' : 'not-allowed' }}>
                                                    {conditionsOptions.map((c, i) => {
                                                        const isSelected = c === selectedCondition ? true : false;
                                                        const label = c ? t(('vehicle.conditions.' + c) as any) : '';
                                                        return renderConditionItem(label, () => onSelectCondition(c), isSelected, i);

                                                    })}
                                                </div>

                                                {(!conditionIsSelected) && <label className={styles.errorLabel}>
                                                    {t('common.errors.required')}
                                                </label>}

                                            </FormItem>
                                        </Col>

                                        {/* cor */}
                                        <Col xs={12} sm={12} md={6} xl={4}>
                                            <FormItem>
                                                <Label className={styles.label}>{t('vehicle.color')}</Label>
                                                <SelectController
                                                    form={form}
                                                    name="colorId"
                                                    placeholder={t('vehicle.color')}
                                                    isDisabled={mode === 'details'}
                                                    options={colorsOptions}
                                                    rules={{ required: false }}
                                                    isClearable={true}
                                                    filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                                    onChangeSelect={(data: SelectValueLabel) => {
                                                        setValue('colorId', data ? data.value : null);
                                                    }}
                                                />
                                                <InputError error={errors.colorId} />
                                            </FormItem>
                                        </Col>

                                        {/* Moyenne objectif mois */}
                                        {showField(SEGMENT_FEATURES.KM) && <Col xs={12} sm={12} md={6} xl={4}>
                                            <FormItem>
                                                <Label className={styles.label}>{t('vehicle.average_target_month')}</Label>
                                                <Controller
                                                    render={({ }) => {
                                                        return (
                                                            <InputGroup
                                                                style={{ textAlign: 'end' }} format={'number'}
                                                                placeholder={t('vehicle.average_target_month')}
                                                                text={t('vehicle.km')}
                                                                value={form.getValues('averageTargetMonth')}
                                                                onValueChange={value => {
                                                                    setValue('averageTargetMonth', value);
                                                                }}
                                                                disabled={mode === 'details'} />
                                                        );
                                                    }}
                                                    control={control}
                                                    name="averageTargetMonth"
                                                    defaultValue={form.getValues('averageTargetMonth')}
                                                />
                                            </FormItem>
                                        </Col>}
                                    </Row>
                                </Col>
                            </Row>

                            <Row className={styles.fullWidth}>
                                {/* poder fiscal */}
                                {showField(SEGMENT_FEATURES.KM) && <Col xs={12} sm={12} md={6} xl={3}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('vehicle.fiscal_power')}</Label>
                                        <InputController
                                            name="fiscalPower"
                                            form={form as any}
                                            autoComplete='off'
                                            placeholder={t('vehicle.fiscal_power')}
                                            disabled={mode === 'details'}
                                        />
                                    </FormItem>
                                </Col>}

                                {/* disponivel para reservar */}
                                <Col xs={12} sm={12} md={6} xl={3}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('vehicle.available_for_reservation')}</Label>
                                        <ToggleSwitch
                                            name="reservationAvailable"
                                            form={form}
                                            isDetails={mode === 'details'}
                                        />
                                    </FormItem>
                                </Col>

                                {/* condutor por defeito */}
                                <Col xs={12} sm={12} md={6} xl={3}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('vehicle.default_driver')}</Label>
                                        <SelectController
                                            form={form}
                                            name="defaultDriverId"
                                            placeholder={t('vehicle.default_driver')}
                                            isDisabled={mode === 'details' || !!form.watch('reservationAvailable')}
                                            options={driversOptions}
                                            isClearable={true}
                                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                            onChangeSelect={(data: SelectValueLabel) => {
                                                setValue('defaultDriverId', data ? data.value : null);
                                            }}
                                        />
                                    </FormItem>
                                </Col>


                                {/* agência */}
                                <Col xs={12} sm={12} md={6} xl={3}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('vehicle.locals')}</Label>
                                        <Controller
                                            render={({ onChange, value }) => {
                                                return (
                                                    <Select
                                                        options={localsOptions}
                                                        isClearable
                                                        isMulti
                                                        isDisabled={mode === 'details'}
                                                        placeholder={t('vehicle.locals')}
                                                        onChange={(data: SelectValueLabel[]) => {
                                                            onChange(data?.map(x => ({ localId: x.value })));
                                                        }}
                                                        value={value ? localsOptions.filter(x => value.find((y: LocalBasicInfoViewModel) => y.localId === x.value)) : null}
                                                    />
                                                );
                                            }}
                                            control={control}
                                            name="vehicleLocals"
                                            defaultValue={getValues('vehicleLocals')} />
                                        <InputError error={errors.vehicleLocals as any} />
                                    </FormItem>
                                </Col>

                                {/* cartao combustivel */}
                                {showField(SEGMENT_FEATURES.FUEL) && <Col xs={12} sm={12} md={6} xl={3}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('vehicle.fuel_card')}</Label>
                                        <Input
                                            name="fuelCard"
                                            placeholder={t('vehicle.fuel_card')}
                                            ref={register()}
                                            autoComplete='off'
                                            disabled={mode === 'details'}
                                        />
                                    </FormItem>
                                </Col>}

                                {showField(SEGMENT_FEATURES.FUEL) && <Col xs={12} sm={12} md={6} xl={3}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('vehicle.fuel_card_supplier')}</Label>
                                        <SelectController
                                            form={form}
                                            name="fuelCardSupplierId"
                                            placeholder={t('vehicle.supplier')}
                                            isDisabled={mode === 'details'}
                                            options={fuelSuppliersOptions}
                                            isClearable={true}
                                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                            onChangeSelect={(data: SelectValueLabel) => {
                                                setValue('fuelCardSupplierId', data ? data.value : null);
                                            }}
                                        />
                                    </FormItem>
                                </Col>}

                                {((item?.companyTrackingProvider && item?.companyTrackingProvider !== TrackingProvider.NONE) || (userProfile?.trackingProvider && userProfile?.trackingProvider !== TrackingProvider.NONE)) && <Col xs={12} sm={12} md={6} xl={3}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('vehicle.tracking_device')}</Label>
                                        {!isLoadingDevicesList && <SelectController
                                            form={form}
                                            name="trackingDeviceId"
                                            placeholder={t('vehicle.tracking_device')}
                                            isDisabled={mode === 'details'}
                                            options={trackingDevicesOptions}
                                            isClearable={true}
                                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                        />}
                                        {isLoadingDevicesList && <LoadingSpinner />}
                                    </FormItem>
                                </Col>}
                            </Row>
                        </Row>

                        {showField(SEGMENT_FEATURES.FUEL) && <Row className={styles.fullWidth}>
                            <VehicleFuelForm
                                form={form}
                                isDetails={mode === 'details'}
                                mode={mode}
                                fuelsOptions={fuelsOptions}
                            />
                        </Row>}

                        <Row className={styles.fullWidth}>
                            <TechnicalInformationForm
                                form={form}
                                isDetails={mode === 'details'}
                                onRemoveTechnicalInformation={onRemoveTechnicalInformation}
                            />
                        </Row>
                    </Row>
                </div>

                <div className={styles.buttonsContainer}>
                    {(returnUrl || mode === 'edit') && <Button
                        preset={'secondary'}
                        type="button"
                        onClick={() => navigateTo()}
                        text={t('common.cancel')}
                    />}
                    {item && item.active && mode === ('details') && hasVehiclesWritePolicy && <Button
                        type="button"
                        text={t('common.deactivate')}
                        preset={'danger'}
                        onClick={() => showRemoveItemDialog({ id: item?.id } as VehicleViewModel)} />}


                    {item && !item.active && mode === ('details') && hasVehiclesWritePolicy && <Button
                        type="button"
                        text={t('common.activate')}
                        preset={'primary'}
                        onClick={() => showActivateItemDialog({ id: item?.id } as VehicleViewModel)} />}


                    {mode === ('details') && item && item.active && hasVehiclesWritePolicy &&
                        <Button
                            type={'button'}
                            text={t('common.edit')}
                            onClick={() => { navigateTo('edit', item?.id); }}
                        />}

                    {hasVehiclesWritePolicy && mode !== ('details') && <Button
                        type={'submit'}
                        text={t('common.save')}
                    />}

                    <QuestionYesNo
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} >
                        <div>
                            <span> {t('vehicle.deactivate_message')}</span> <br />
                            <span>{t('common.messages.deactivate_record')}</span>
                        </div>
                    </QuestionYesNo>


                    <QuestionYesNo
                        isVisible={dialogActivateItemIsOpen}
                        onYes={() => activateItem()}
                        onNo={() => setDialogActivateItemIsOpen(false)} >
                        <div>
                            <span>{t('common.messages.activate_record')}</span>
                        </div>
                    </QuestionYesNo>


                </div>
            </Box>
        </form >
    );
}

export default CarForm;